import {
  Sections,
  Section,
  Question,
  QuestionKind,
  QuestionOperator,
} from "./model";

export const SECTIONS_TEST: Sections = {
  helper: {
    label: "Help",
    questions: {
      help: {
        name: "help",
        label: "How to Use Your Tailored Form",
        kind: QuestionKind.help,
        disabled: true,
      },
    },
  },
  jobBasics: {
    label: "Job Basics",
    questions: {
      jobName: {
        name: "jobName",
        label: "Job Position",
        kind: QuestionKind.tags,
        props: { tags: "JOBS_NAME" },
      },
      locationsAccepted: {
        name: "locations",
        label: "Accepted Locations",
        kind: QuestionKind.tags,
        props: { tags: "LOCATIONS" },
        operator: QuestionOperator.oneOf,
      },
      locationsRefused: {
        name: "locations",
        label: "Refused Locations",
        kind: QuestionKind.tags,
        props: { tags: "LOCATIONS" },
        operator: QuestionOperator.noneOf,
      },
      remotePercentage: {
        name: "remotePercentage",
        label: "Minimum Percentage of Remote",
        kind: QuestionKind.range,
        props: {
          min: 0,
          max: 100,
          step: 10,
          marks: [
            { value: 0, label: "Full Office" },
            { value: 10 },
            { value: 20 },
            { value: 30 },
            { value: 40 },
            { value: 50, label: "50/50" },
            { value: 60 },
            { value: 70 },
            { value: 80 },
            { value: 90 },
            { value: 100, label: "Full Remote" },
          ],
        },
      },
      startDate: {
        name: "startDate",
        label: "Start date",
        kind: QuestionKind.date,
      },
    },
  },
  freelancer: {
    label: "Freelancer",
    questions: {
      dailyRate: {
        name: "dailyRate",
        label: "Daily Rate",
        kind: QuestionKind.money,
        disabled: false,
      },
      payementDelay: {
        name: "payementDelay",
        label: "Max Payment Delay",
        kind: QuestionKind.duration,
      },
      minDuration: {
        name: "minDuration",
        label: "At Least",
        kind: QuestionKind.duration,
      },
      maxDuration: {
        name: "maxDuration",
        label: "At most",
        kind: QuestionKind.duration,
      },
    },
  },
  permanent: {
    label: "Permanent",
    questions: {
      yearlySalary: {
        name: "yearlySalary",
        label: "Yearly Salary",
        kind: QuestionKind.money,
      },
      vacations: {
        name: "vacations",
        label: "Paid Vacations",
        kind: QuestionKind.duration,
      },
    },
  },
  compensation: {
    label: "Compensation & Bonus",
    questions: {
      signingBonus: {
        name: "signingBonus",
        label: "Signing Bonus",
        kind: QuestionKind.money,
      },
      relocationPackage: {
        name: "relocationPackage",
        label: "Relocation Package",
        kind: QuestionKind.money,
      },
      other: {
        name: "stockOptions",
        label: "Stock Options",
        kind: QuestionKind.checkbox,
        items: {
          stockOptions: {
            name: "stockOptions",
            label: "Stock Options",
            kind: QuestionKind.checkbox,
          },
          royalties: {
            name: "royalties",
            label: "Royalties",
            kind: QuestionKind.checkbox,
          },
          tokens: {
            name: "tokens",
            label: "Tokens",
            kind: QuestionKind.checkbox,
          },
        },
      },
    },
  },
  teamAndCulture: {
    label: "Team & Culture",
    questions: {
      teamSize: {
        name: "teamSize",
        label: "Team Size",
        kind: QuestionKind.range,
        value: [1, 30],
        props: {
          min: 1,
          max: 30,
          marks: [
            { value: 1, label: "1" },
            { value: 5, label: "5" },
            { value: 10, label: "10" },
            { value: 20, label: "20" },
            { value: 30, label: "30+" },
          ],
        },
      },
      amenities: {
        name: "amenities",
        label: "Amenities",
        kind: QuestionKind.tags,
        props: { tags: "AMENITIES" },
      },
      equipmentProvided: {
        name: "equipmentProvided",
        label: "Equipment Provided",
        kind: QuestionKind.checkbox,
        items: {
          laptop: {
            name: "laptop",
            label: "Laptop",
            kind: QuestionKind.checkbox,
          },
          monitor: {
            name: "monitor",
            label: "Monitor",
            kind: QuestionKind.checkbox,
          },
          keyboard: {
            name: "keyboard",
            label: "Keyboard",
            kind: QuestionKind.checkbox,
          },
          ergoChair: {
            name: "ergoChair",
            label: "Ergonomic Chair",
            kind: QuestionKind.checkbox,
          },
        },
      },
      recurrentMeeting: {
        name: "recurrentMeeting",
        label: "Recurrent Meeting",
        kind: QuestionKind.checkbox,
        items: {
          dailyMeeting: {
            name: "dailyMeeting",
            label: "Daily Meeting",
            kind: QuestionKind.checkbox,
          },
          weeklyMeeting: {
            name: "weeklyMeeting",
            label: "Weekly Meeting",
            kind: QuestionKind.checkbox,
          },
        },
      },
      osOnLaptop: {
        name: "osOnLaptop",
        label: "Operating System on Workstation/Laptop",
        kind: QuestionKind.checkbox,
        items: {
          windows: {
            name: "windows",
            label: "Windows",
            kind: QuestionKind.checkbox,
          },
          mac: { name: "mac", label: "Mac", kind: QuestionKind.checkbox },
          linux: { name: "linux", label: "Linux", kind: QuestionKind.checkbox },
        },
      },
    },
  },
  technicalRequirements: {
    label: "Technical Requirements",
    questions: {
      techs: {
        name: "techs",
        label: "Tech Stack",
        kind: QuestionKind.multiple,
        items: {
          seniorTech: {
            name: "seniorTech",
            label: "Senior Level",
            kind: QuestionKind.tags,
            props: { tags: "TECHS" },
          },
          learningTech: {
            name: "learningTech",
            label: "Happy to Learn",
            kind: QuestionKind.tags,
            props: { tags: "TECHS" },
          },
          nopTechs: {
            name: "nopTechs",
            label: "Unwanted Technologies",
            kind: QuestionKind.tags,
            props: { tags: "TECHS" },
          },
        },
      },
      osToWorkWith: {
        name: "osToWorkWith",
        label: "Operating System to Work With",
        kind: QuestionKind.multiple,
        items: {
          happy: {
            name: "happy",
            label: "Accepted",
            kind: QuestionKind.tags,
            props: { tags: "OS" },
          },
          avoid: {
            name: "avoid",
            label: "Avoiding",
            kind: QuestionKind.tags,
            props: { tags: "OS" },
          },
        },
      },
      jobDescription: {
        name: "jobDescription",
        label: "Job Description",
        kind: QuestionKind.area,
        helperText: "Recruiters, tell us about that job",
      },
    },
  },
};

export const RESULTS: Sections = {
  helper: {
    label: "Help",
    questions: {
      help: {
        name: "helper.questions.help",
        label: "How to Use Your Tailored Form",
        kind: "help",
        disabled: true,
        mainName: "helper.questions.help",
      },
    },
    name: "helper",
    help: {},
  },
  jobBasics: {
    label: "Job Basics",
    questions: {
      jobName: {
        name: "jobBasics.questions.jobName",
        label: "Job Position",
        kind: "tags",
        props: {
          tags: "JOBS_NAME",
        },
        mainName: "jobBasics.questions.jobName",
        value: ["Business Analyst", "Software Developer"],
        disabled: false,
      },
      locations: {
        name: "jobBasics.questions.locations",
        label: "Locations",
        kind: "multiple",
        items: {
          want: {
            name: "jobBasics.questions.locations.want",
            label: "Wanted",
            kind: "tags",
            props: {
              tags: "LOCATIONS",
            },
            operator: "oneOf",
            mainName: "jobBasics.questions.locations",
          },
          avoid: {
            name: "jobBasics.questions.locations.avoid",
            label: "I do not want",
            kind: "tags",
            props: {
              tags: "LOCATIONS",
            },
            operator: "noneOf",
            mainName: "jobBasics.questions.locations",
          },
        },
        mainName: "jobBasics.questions.locations",
        want: {
          value: ["Deventer", "Talara"],
        },
        avoid: {
          value: ["Cajamarca"],
        },
        disabled: true,
      },
      remotePercentage: {
        name: "jobBasics.questions.remotePercentage",
        label: "Minimum Percentage of Remote",
        kind: "range",
        props: {
          min: 0,
          max: 100,
          step: 10,
          marks: [
            {
              value: 0,
              label: "Full Office",
            },
            {
              value: 10,
            },
            {
              value: 20,
            },
            {
              value: 30,
            },
            {
              value: 40,
            },
            {
              value: 50,
              label: "50/50",
            },
            {
              value: 60,
            },
            {
              value: 70,
            },
            {
              value: 80,
            },
            {
              value: 90,
            },
            {
              value: 100,
              label: "Full Remote",
            },
          ],
        },
        mainName: "jobBasics.questions.remotePercentage",
        value: "100",
      },
      startDate: {
        name: "jobBasics.questions.startDate",
        label: "Start date",
        kind: "date",
        mainName: "jobBasics.questions.startDate",
        value: "2023-10-13T23:00:00.000Z",
      },
    },
    name: "jobBasics",
    jobName: {},
    locations: {},
    remotePercentage: {},
    startDate: {},
  },
  freelancer: {
    label: "Freelancer",
    questions: {
      dailyRate: {
        name: "freelancer.questions.dailyRate",
        label: "Daily Rate",
        kind: "money",
        disabled: false,
        mainName: "freelancer.questions.dailyRate",
        value: "800",
        props: {
          currency: "EUR",
        },
      },
      payementDelay: {
        name: "freelancer.questions.payementDelay",
        label: "Max Payment Delay",
        kind: "duration",
        mainName: "freelancer.questions.payementDelay",
        value: "1",
        props: {
          duration: "months",
        },
      },
      minDuration: {
        name: "freelancer.questions.minDuration",
        label: "Minimal Duration",
        kind: "duration",
        mainName: "freelancer.questions.minDuration",
        value: "1",
        props: {
          duration: "months",
        },
      },
      maxDuration: {
        name: "freelancer.questions.maxDuration",
        label: "Maximal Duration",
        kind: "duration",
        mainName: "freelancer.questions.maxDuration",
        value: "",
        props: {
          duration: "months",
        },
      },
    },
    name: "freelancer",
    dailyRate: {},
    payementDelay: {},
    duration: {},
    minDuration: {},
    maxDuration: {
      disabled: true,
    },
  },
  permanent: {
    label: "Permanent",
    questions: {
      yearlySalary: {
        name: "permanent.questions.yearlySalary",
        label: "Yearly Salary",
        kind: "money",
        mainName: "permanent.questions.yearlySalary",
        value: "",
        props: {
          currency: "",
        },
      },
      vacations: {
        name: "permanent.questions.vacations",
        label: "Paid Vacations",
        kind: "duration",
        mainName: "permanent.questions.vacations",
        value: "",
        props: {
          duration: "months",
        },
      },
    },
    name: "permanent",
    yearlySalary: {
      disabled: true,
    },
    vacations: {
      disabled: true,
    },
  },
  compensation: {
    label: "Compensation & Bonus",
    questions: {
      signingBonus: {
        name: "compensation.questions.signingBonus",
        label: "Signing Bonus",
        kind: "money",
        mainName: "compensation.questions.signingBonus",
        value: "",
        props: {
          currency: "",
        },
      },
      relocationPackage: {
        name: "compensation.questions.relocationPackage",
        label: "Relocation Package",
        kind: "money",
        mainName: "compensation.questions.relocationPackage",
        value: "",
        props: {
          currency: "",
        },
      },
      other: {
        name: "compensation.questions.other",
        label: "Stock Options",
        kind: "checkbox",
        items: {
          stockOptions: {
            name: "stockOptions",
            label: "Stock Options",
            kind: "checkbox",
          },
          royalties: {
            name: "royalties",
            label: "Royalties",
            kind: "checkbox",
          },
          tokens: {
            name: "tokens",
            label: "Tokens",
            kind: "checkbox",
          },
        },
        mainName: "compensation.questions.other",
        stockOptions: {
          value: false,
        },
        royalties: {
          value: false,
        },
        tokens: {
          value: false,
        },
      },
    },
    name: "compensation",
    signingBonus: {},
    relocationPackage: {},
    other: {},
  },
  teamAndCulture: {
    label: "Team & Culture",
    questions: {
      teamSize: {
        name: "teamAndCulture.questions.teamSize",
        label: "Team Size",
        kind: "range",
        value: ["3", "5"],
        props: {
          min: 1,
          max: 30,
          marks: [
            {
              value: 1,
              label: "1",
            },
            {
              value: 5,
              label: "5",
            },
            {
              value: 10,
              label: "10",
            },
            {
              value: 20,
              label: "20",
            },
            {
              value: 30,
              label: "30+",
            },
          ],
        },
        mainName: "teamAndCulture.questions.teamSize",
      },
      amenities: {
        name: "teamAndCulture.questions.amenities",
        label: "Amenities",
        kind: "tags",
        props: {
          tags: "AMENITIES",
        },
        mainName: "teamAndCulture.questions.amenities",
      },
      equipmentProvided: {
        name: "teamAndCulture.questions.equipmentProvided",
        label: "Equipment Provided",
        kind: "checkbox",
        items: {
          laptop: {
            name: "laptop",
            label: "Laptop",
            kind: "checkbox",
          },
          monitor: {
            name: "monitor",
            label: "Monitor",
            kind: "checkbox",
          },
          keyboard: {
            name: "keyboard",
            label: "Keyboard",
            kind: "checkbox",
          },
          ergoChair: {
            name: "ergoChair",
            label: "Ergonomic Chair",
            kind: "checkbox",
          },
        },
        mainName: "teamAndCulture.questions.equipmentProvided",
        laptop: {
          value: false,
        },
        monitor: {
          value: false,
        },
        keyboard: {
          value: false,
        },
        ergoChair: {
          value: false,
        },
      },
      recurrentMeeting: {
        name: "teamAndCulture.questions.recurrentMeeting",
        label: "Recurrent Meeting",
        kind: "checkbox",
        items: {
          dailyMeeting: {
            name: "dailyMeeting",
            label: "Daily Meeting",
            kind: "checkbox",
          },
          weeklyMeeting: {
            name: "weeklyMeeting",
            label: "Weekly Meeting",
            kind: "checkbox",
          },
        },
        mainName: "teamAndCulture.questions.recurrentMeeting",
        dailyMeeting: {
          value: false,
        },
        weeklyMeeting: {
          value: false,
        },
      },
      osOnLaptop: {
        name: "teamAndCulture.questions.osOnLaptop",
        label: "Operating System on Workstation/Laptop",
        kind: "checkbox",
        items: {
          windows: {
            name: "windows",
            label: "Windows",
            kind: "checkbox",
          },
          mac: {
            name: "mac",
            label: "Mac",
            kind: "checkbox",
          },
          linux: {
            name: "linux",
            label: "Linux",
            kind: "checkbox",
          },
        },
        mainName: "teamAndCulture.questions.osOnLaptop",
        windows: {
          value: false,
        },
        mac: {
          value: false,
        },
        linux: {
          value: false,
        },
      },
    },
    name: "teamAndCulture",
    teamSize: {},
    amenities: {},
    equipmentProvided: {},
    recurrentMeeting: {},
    osOnLaptop: {},
  },
  technicalRequirements: {
    label: "Technical Requirements",
    questions: {
      techs: {
        name: "technicalRequirements.questions.techs",
        label: "Tech Stack",
        kind: "multiple",
        items: {
          seniorTech: {
            name: "technicalRequirements.questions.techs.seniorTech",
            label: "Senior Level",
            kind: "tags",
            props: {
              tags: "TECHS",
            },
            mainName: "technicalRequirements.questions.techs",
          },
          learningTech: {
            name: "technicalRequirements.questions.techs.learningTech",
            label: "Happy to Learn",
            kind: "tags",
            props: {
              tags: "TECHS",
            },
            mainName: "technicalRequirements.questions.techs",
          },
          nopTechs: {
            name: "technicalRequirements.questions.techs.nopTechs",
            label: "Unwanted Technologies",
            kind: "tags",
            props: {
              tags: "TECHS",
            },
            mainName: "technicalRequirements.questions.techs",
          },
        },
        mainName: "technicalRequirements.questions.techs",
        seniorTech: {
          value: ["python", "C++", "docker", "linux"],
        },
        learningTech: {
          value: ["rust", "kotlin"],
        },
        nopTechs: {
          value: [".net", "C#"],
        },
      },
      osToWorkWith: {
        name: "technicalRequirements.questions.osToWorkWith",
        label: "Operating System to Work With",
        kind: "multiple",
        items: {
          happy: {
            name: "technicalRequirements.questions.osToWorkWith.happy",
            label: "Accepted",
            kind: "tags",
            props: {
              tags: "OS",
            },
            mainName: "technicalRequirements.questions.osToWorkWith",
          },
          avoid: {
            name: "technicalRequirements.questions.osToWorkWith.avoid",
            label: "Avoiding",
            kind: "tags",
            props: {
              tags: "OS",
            },
            mainName: "technicalRequirements.questions.osToWorkWith",
          },
        },
        mainName: "technicalRequirements.questions.osToWorkWith",
        happy: {},
        avoid: {
          value: ["Windows"],
        },
      },
      jobDescription: {
        name: "technicalRequirements.questions.jobDescription",
        label: "Job Description",
        kind: "area",
        helperText: "Recruiters, tell us about that job",
        mainName: "technicalRequirements.questions.jobDescription",
        value: "",
      },
    },
    name: "technicalRequirements",
    techs: {},
    osToWorkWith: {},
    jobDescription: {},
  },
} as Sections;
