import {
  Box,
  Grid,
  Container,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Slider,
  Paper,
  Typography,
  Switch,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { Question } from "./model";
import { TAGS_DATA } from "./tagsData";
import {
  Controller,
  Control,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";

export const isDisabeld = (
  question: Question,
  watch: UseFormWatch<any>
): boolean => {
  console.log(
    "switch isDisabled",
    `${question.mainName}.disabled`,
    watch(`${question.mainName}.disabled`)
  );
  return watch(`${question.mainName}.disabled`) === undefined
    ? question.disabled === true
    : watch(`${question.mainName}.disabled`);
};

export const CreateInput = (
  question: Question,
  register: UseFormRegister<any>,
  control: Control,

  watch: UseFormWatch<any>
) => {
  const disabled = isDisabeld(question, watch);
  return (
    <TextField
      {...register(`${question.name}.value`)}
      fullWidth
      defaultValue={question.value || ""}
      label={question.label}
      type={question.kind}
      disabled={disabled}
      variant={disabled ? "filled" : "outlined"}
      helperText={question.helperText}
    />
  );
};

export const CreateTextArea = (
  question: Question, // Assuming Question is a type defined elsewhere
  register: UseFormRegister<any>,
  control: Control,
  watch: UseFormWatch<any>
) => {
  const disabled = isDisabeld(question, watch);

  return (
    <TextField
      {...register(`${question.name}.value`)}
      fullWidth
      rows={4}
      multiline
      defaultValue={question.value || ""}
      label={question.label}
      disabled={disabled}
      variant={disabled ? "filled" : "outlined"}
      helperText={question.helperText}
    />
  );
};

export const CreateHelp = () => {
  return (
    <Paper elevation={3}>
      <Box p={3}>
        <Typography variant="body1" paragraph>
          You can customize your form to get answers from recruiters that matter
          most to you.
        </Typography>
        <Typography variant="body1" paragraph>
          1. Browse through the list of questions we offer.
        </Typography>
        <Typography variant="body1" paragraph>
          2. <Switch defaultChecked /> on the right of each question, enables or
          disables questions based on your interest. Disabling a question will
          hide it from your form.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Filling out a question will activate a filter. For example, if you
          specify "Seattle" and "Los Angeles" for location, you'll only get
          offers from these cities.
        </Typography>
        <Typography variant="body1">
          Leaving a question empty will still prompt the recruiter to answer,
          but won't limit the offers you receive.
        </Typography>
      </Box>
    </Paper>
  );
};

export const CreateTags = (
  question: Question,
  register: UseFormRegister<any>,
  control: Control,
  watch: UseFormWatch<any>
) => {
  const disabled = isDisabeld(question, watch);
  console.log("CreateTags", question.mainName, disabled);
  const options =
    question.props !== undefined && question.props.tags !== undefined
      ? TAGS_DATA[question.props.tags]
      : ([] as any[]);
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 5,
  });

  const defaultValue = Array.isArray(question.value) ? question.value : [];
  console.log("trax>CreateTags>", question.label, defaultValue);
  return (
    <Controller
      name={`${question.name}.value`}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          value={field.value || defaultValue}
          multiple
          freeSolo
          disabled={disabled}
          options={options}
          filterOptions={filterOptions}
          filterSelectedOptions
          defaultValue={defaultValue}
          getOptionLabel={(option) => option}
          onChange={(e, data) => {
            field.onChange(data);
          }}
          renderInput={(params) => (
            <TextField
              variant={disabled ? "filled" : "outlined"}
              {...params}
              label={question.label}
            />
          )}
        />
      )}
    />
  );
};

export const CreateMoney = (
  question: Question, // Assuming Question is a type defined elsewhere
  register: UseFormRegister<any>,
  control: Control,
  watch: UseFormWatch<any>
) => {
  const currencies = [
    {
      value: "USD",
      label: "$",
    },
    {
      value: "EUR",
      label: "€",
    },
    {
      value: "BTC",
      label: "฿",
    },
    {
      value: "JPY",
      label: "¥",
    },
  ];

  const disabled = isDisabeld(question, watch);
  return (
    <Grid container spacing={4}>
      <Grid item xs={10}>
        {CreateInput(question, register, control, watch)}
      </Grid>
      <Grid item xs={2}>
        <TextField
          {...register(`${question.name}.props.currency`)}
          fullWidth
          select
          label="Currency"
          disabled={disabled}
          defaultValue={question.props?.currency}
          type="number"
          variant={disabled ? "filled" : "outlined"}
        >
          {currencies.map((option, index) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export const CreateDuration = (
  question: Question, // Assuming Question is a type defined elsewhere
  register: UseFormRegister<any>,
  control: Control,
  watch: UseFormWatch<any>
) => {
  const currencies = [
    {
      value: "days",
      label: "Days",
    },
    {
      value: "weeks",
      label: "Weeks",
    },
    {
      value: "months",
      label: "Months",
    },
    {
      value: "na",
      label: "N/A",
    },
  ];

  const disabled = isDisabeld(question, watch);
  return (
    <Grid container spacing={4}>
      <Grid item xs={10}>
        {CreateInput(question, register, control, watch)}
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          {...register(`${question.name}.props.duration`)}
          select
          label="Unit"
          defaultValue="months"
          disabled={disabled}
          type="number"
          variant={disabled ? "filled" : "outlined"}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export const CreateCheckbox = (
  question: Question, // Assuming Question is a type defined elsewhere
  register: UseFormRegister<any>,
  control: Control,
  watch: UseFormWatch<any>
) => {
  let checks: Array<JSX.Element> = [];
  const disabled = isDisabeld(question, watch);

  for (const [key, subQuestion] of Object.entries(question.items ?? {})) {
    checks.push(
      <FormControlLabel
        key={key}
        control={
          <Checkbox
            disabled={disabled}
            {...register(`${question.name}.${subQuestion.name}.value`)}
            defaultChecked={subQuestion.item === true ? true : false}
          />
        }
        label={subQuestion.label}
      />
    );
  }
  return (
    <Box>
      {question.label}
      <Container>{checks}</Container>
    </Box>
  );
};

export const CreateRange = (
  question: Question,
  register: UseFormRegister<any>,
  control: Control,
  watch: UseFormWatch<any>
) => {
  const disabled = isDisabeld(question, watch);
  let defaultValue: number | number[];

  if (question.value !== undefined) {
    if (Array.isArray(question.value)) {
      defaultValue = question.value as number[];
    } else if (typeof question.value === "number") {
      defaultValue = question.value as number;
    } else if (typeof question.value === "string") {
      defaultValue = parseInt(question.value);
    }
  }

  return (
    <Box>
      {question.label}

      <Container sx={{ width: "50%" }}>
        <Controller
          name={`${question.name}.value`}
          control={control}
          render={({ field }) => (
            <Slider
              {...field}
              disabled={disabled}
              valueLabelDisplay="auto"
              defaultValue={defaultValue}
              step={question.props?.step as number}
              min={question.props?.min as number}
              max={question.props?.max as number}
              marks={question.props?.marks as any}
              onChange={(e, data) => {
                field.onChange(data);
              }}
              value={field.value || defaultValue}
            />
          )}
        />
      </Container>
    </Box>
  );
};

export const CreateDate = (
  question: Question, // Assuming Question is a type defined elsewhere
  register: UseFormRegister<any>,
  control: Control,
  watch: UseFormWatch<any>
) => {
  const disabled = isDisabeld(question, watch);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Controller
        name={`${question.name}.value`}
        control={control}
        render={({ field }) => (
          <DatePicker
            disabled={disabled}
            label={question.label}
            onChange={(e) => {
              field.onChange(e);
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
