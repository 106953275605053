import fetcher from "./fetcher";
import LOCATIONS from "./locations.json";
const OS: Array<string> = [
  "Windows",
  "macOS",
  "Linux",
  "Ubuntu",
  "Fedora",
  "Debian",
  "CentOS",
  "Red Hat",
  "FreeBSD",
  "OpenBSD",
  "Solaris",
  "Android",
  "iOS",
];

const TECHS: Array<string> = [
  "Spring Boot",
  "NumPy",
  "Vue.js",
  "R",
  "Python",
  "Continuous integration/continuous delivery (CI/CD) tools",
  "webDevelopment",
  "Switches",
  "Alibaba Cloud",
  "Android",
  "D3.js",
  "Personal computers",
  "Intrusion prevention systems (IPS)",
  "DNS servers",
  "Mobile development tools",
  "JavaScript",
  "Angular",
  "Perl",
  "Machine learning tools",
  "Smartphones",
  "Redis",
  "Lua",
  "CSS",
  "Elasticsearch",
  "Scikit-learn",
  "MongoDB",
  "DynamoDB",
  "Dart",
  "Amazon Web Services (AWS)",
  "Keras",
  "Moment.js",
  "Pandas",
  "Unit testing frameworks",
  "Game development tools",
  "Intrusion detection systems (IDS)",
  "Haskell",
  "PHP",
  "Version control systems (VCS)",
  "Go",
  "macOS",
  "TypeScript",
  "Rust",
  "MySQL",
  "Ruby",
  "Data encryption",
  "Web development tools",
  "Axios",
  "C ansi",
  "C99",
  "C++",
  "C++17",
  "C++20",
  "Microsoft Azure",
  "TensorFlow",
  "Tablets",
  "jQuery",
  "Antivirus software",
  "Oracle",
  "Cloud computing infrastructure",
  "Shell",
  "Objective-C",
  "iOS",
  "SciPy",
  "OpenCV",
  "React",
  "Cassandra",
  "Windows",
  "PostgreSQL",
  "Rails",
  "Servers",
  "Node.js",
  "Load balancers",
  "C#",
  "mobileDevelopment",
  "Django",
  "Kotlin",
  "Laravel",
  "Integrated development environments (IDEs)",
  "Swift",
  "Scala",
  "Artificial intelligence tools",
  "MATLAB",
  "Linux",
  "Google Cloud Platform (GCP)",
  "Firewalls",
  "Data science tools",
  "Java",
  "Matplotlib",
  "Microsoft SQL Server",
  "Lodash",
  "Routers",
  "Three.js",
  "PyTorch",
  "Laptops",
  "Groovy",
  "spaCy",
  "ASP.NET Core",
  "git",
  "gitlab",
  "github",
  "docker",
  "terraform",
  "ansible",
  "puppet",
  "salt",
  "AWS cdk",
  "AWS sam",
  "Amazon Elastic Compute Cloud (EC2)",
  "Amazon Simple Storage Service (S3)",
  "Amazon Relational Database Service (RDS)",
  "Amazon DynamoDB",
  "Amazon Simple Notification Service (SNS)",
  "Amazon Simple Queue Service (SQS)",
  "Amazon Elastic Kubernetes Service (EKS)",
  "Amazon Elastic Beanstalk",
  "Amazon Lambda",
  "Amazon CloudFront",
  "Amazon Route 53",
  "Amazon CloudWatch",
  "Amazon CloudTrail",
  "Amazon GuardDuty",
  "Amazon Inspector",
  "Amazon CloudSearch",
  "Amazon Translate",
  "Amazon Lex",
  "Amazon Polly",
  "Amazon Rekognition",
  "Amazon SageMaker",
  "App Engine",
  "Compute Engine",
  "Cloud Storage",
  "BigQuery",
  "Cloud Dataproc",
  "Cloud Dataflow",
  "Cloud Functions",
  "Cloud ML Engine",
  "Cloud Natural Language",
  "Cloud Vision API",
  "Cloud Speech-to-Text",
  "App Service",
  "Azure Compute",
  "Azure Storage",
  "Azure SQL Database",
  "Azure Cosmos DB",
  "Azure Databricks",
  "Azure Functions",
  "Azure Machine Learning",
  "Azure Cognitive Services",
  "Azure IoT Hub",
  "Azure Power BI",
];

const AMENITIES: Array<string> = [
  "24/7 Access",
  "3D Printer",
  "ATM",
  "After Work Area",
  "Air Conditioning",
  "Arcade Games",
  "Art Supplies",
  "BBQ Area",
  "Barista Service",
  "Bike Storage",
  "Biometric Access",
  "Board Games",
  "Break Room",
  "Breakfast",
  "Cafeteria",
  "Car Wash Services",
  "Cleaning Services",
  "Coding Bootcamps",
  "Complimentary Snacks and Drinks",
  "Conference Rooms",
  "Courier Services",
  "Dental Insurance",
  "Dry Cleaning Services",
  "Employee Discounts",
  "Ergonomic Chairs",
  "Financial Planning Services",
  "Fireplace",
  "First Aid Kit",
  "Fish Tank",
  "Fitness Classes",
  "Fruits",
  "Game Room",
  "Guest Speakers",
  "Guest Waiting Area",
  "Heating System",
  "High-Speed Internet",
  "Indoor Plants",
  "Karaoke Room",
  "Kitchenette",
  "Lactation Room",
  "Language Classes",
  "Legal Consultation",
  "Library",
  "Life Insurance",
  "Lockers",
  "Lounge Area",
  "Lunch",
  "Mail Handling",
  "Massage Chairs",
  "Massages",
  "Meditation Room",
  "Mentorship Programs",
  "Microwave",
  "Music Room",
  "Nap Pods",
  "Natural Light",
  "Nutritionist Consultation",
  "On-site Childcare",
  "On-site Gym",
  "Open Workspaces",
  "Outdoor Sports Facilities",
  "Outdoor Terrace",
  "Paid Time Off",
  "Parking Space",
  "Pet Care Services",
  "Pet-Friendly Environment",
  "Phone Booths",
  "Photography Studio",
  "Pool Table",
  "Printer and Scanner",
  "Private Offices",
  "Reception Services",
  "Refrigerator",
  "Restrooms",
  "Rooftop Garden",
  "Security Cameras",
  "Sewing Machine",
  "Showers",
  "Skill Development Courses",
  "Soundproof Rooms",
  "Standing Desks",
  "Storage Space",
  "Swimming Pool",
  "Team Building Activities",
  "Tennis Table",
  "Travel Services",
  "Vending Machines",
  "Video Conferencing Equipment",
  "Vision Insurance",
  "Water Cooler",
  "Workshops",
  "Yoga Studio",
];

const JOBS_NAME: Array<string> = [
  "Software Developer",
  "System Analyst",
  "Business Analyst",
  "Technical Support",
  "Network Engineer",
  "Web Developer",
  "Database Administrator",
  "Data Analyst",
  "Security Analyst",
  "Cloud Architect",
  "DevOps Engineer",
  "UX/UI Designer",
  "IT Manager",
  "Systems Administrator",
  "QA Tester",
  "Mobile App Developer",
  "AI Engineer",
  "Machine Learning Engineer",
  "Cybersecurity Specialist",
  "Technical Consultant",
  "Information Security Manager",
  "Help Desk Technician",
  "Network Administrator",
  "Application Support Analyst",
  "IT Auditor",
  "Technical Writer",
  "Software Architect",
  "Hardware Engineer",
  "Full Stack Developer",
  "Game Developer",
  "Product Manager",
  "IT Sales",
  "Data Scientist",
  "Front-end Developer",
  "Back-end Developer",
  "Data Engineer",
  "Cloud Developer",
  "Big Data Analyst",
  "Virtual Reality Developer",
  "Augmented Reality Developer",
  "SEO Specialist",
  "Content Manager",
  "Infrastructure Engineer",
  "Digital Marketing Specialist",
  "IT Compliance Analyst",
  "E-commerce Specialist",
  "Telecommunications Specialist",
  "Blockchain Developer",
  "Embedded Systems Engineer",
  "Information Research Scientist",
  "Scrum Master",
  "Agile Coach",
  "Solutions Architect",
  "Automation Engineer",
  "Computer Vision Engineer",
  "RPA Developer",
];

export const TAGS_DATA: Record<string, Array<string>> = {
  TECHS: TECHS,
  OS: OS,
  LOCATIONS: LOCATIONS,
  JOBS_NAME: JOBS_NAME,
  AMENITIES: AMENITIES,
};
