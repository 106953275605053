import { setState } from "./Context";

export const getProfileIdFromUrl = () => {

  const profileId = window.location.pathname.split("/").pop();
  if (profileId === undefined || profileId.length !== 24) {
    return undefined;
  }
  return profileId;
};


export  const isValidEmail = (email:string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

export  const checkPassword = (str:string) => {
    if (!str) {
      return [];
    }
    let unmetConditions = [];

    if (str.length < 10) unmetConditions.push("Length less than 10");
    if (!/[0-9]/.test(str)) unmetConditions.push("No number found");
    if (!/[a-z]/.test(str)) unmetConditions.push("No lowercase letter found");
    if (!/[A-Z]/.test(str)) unmetConditions.push("No uppercase letter found");

    return unmetConditions;
  };


export interface SnackProps {
    message:string;
    severity:string;
}

export const snackMessage = (snackProps : SnackProps) => {

    setState("snackBar", {...snackProps, ["open"]:true});
}
