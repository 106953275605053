import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { useTheme } from "@mui/material/styles";
import img_matching from "./undraw_matching.svg";
import img_form from "./undraw_form.svg";
import img_hire from "./undraw_hire.svg";
import TransitionOnVisible from "./TransitionOnVisible";
import { getStateSetter } from "./Context";
import { snackMessage, SnackProps } from "./utils";

import { SECTIONS_TEST } from "./fake";
function LandingPage() {
  const theme = useTheme();

  return (
    <Box px={2}>
      <ParallaxProvider>
        <Paper elevation={24} sx={{ minHeight: "80vh" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={4}
            py={3}
            sx={{}}
          >
            <Grid
              item
              md={12}
              lg={6}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    "&::first-letter": {
                      fontSize: "1.1em",
                    },
                    fontSize: "3rem",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    WebkitTextStrokeWidth: "1px",
                  }}
                >
                  Let's
                </Typography>

                <Typography
                  sx={{
                    textTransform: "uppercase",
                    "&::first-letter": {
                      fontSize: "1.1em",
                    },
                    fontSize: "5rem",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    WebkitTextStrokeWidth: "2px",
                  }}
                >
                  Match IT
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  It's not recruitment, it's matching people
                </Typography>
              </Grid>
              <Grid item my={3}>
                <Button
                  variant="contained"
                  onClick={() => {
                    console.log("rax", JSON.stringify(SECTIONS_TEST, null, 2));
                    getStateSetter("showModal")(true);
                  }}
                >
                  Join Now, it's free :)
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              lg={6}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Fade in={true} timeout={2000}>
                <img
                  src={img_matching}
                  alt="matching people"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Fade>
            </Grid>
          </Grid>
        </Paper>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={8}
          py={12}
          sx={{ backgroundColor: theme.palette.backgrounds.main }}
        >
          <Grid
            item
            md={12}
            lg={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TransitionOnVisible
                kind="slide"
                timeout={1500}
                direction="right"
              >
                <Box>
                  <Typography variant="h3">
                    Ready for a Career Upgrade?
                  </Typography>
                  <Box mt={2}>
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      🛡️ Bypass bots with verified matching
                    </Typography>
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      📍 Tailor your job search: location, tech stack, culture
                    </Typography>
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      🎯 Receive offers that align with your goals
                    </Typography>
                  </Box>
                </Box>
              </TransitionOnVisible>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            lg={6}
            container
            alignItems="center"
            justifyContent="center"
          >
            <TransitionOnVisible kind="fade">
              <img
                src={img_form}
                alt="matching people"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </TransitionOnVisible>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={8}
          py={12}
          sx={{ backgroundColor: theme.palette.backgrounds.light }}
        >
          <Grid
            item
            md={12}
            lg={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <TransitionOnVisible kind="fade">
              <img
                src={img_hire}
                alt="matching people"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </TransitionOnVisible>
          </Grid>
          <Grid item>
            <TransitionOnVisible kind="slide" timeout={1500} direction="left">
              <Box>
                <Typography variant="h3">Looking for a new hire?</Typography>
                <Box mt={2}>
                  <Typography sx={{ fontSize: "1.5rem" }}>
                    🎯 Match with candidates that fit, no résumé decoding
                  </Typography>
                  <Typography sx={{ fontSize: "1.5rem" }}>
                    🤝 Direct connections with pre-qualified talent
                  </Typography>
                  <Typography sx={{ fontSize: "1.5rem" }}>
                    🚀 Align offers with candidate ambitions for long-term
                    success
                  </Typography>
                </Box>
              </Box>
            </TransitionOnVisible>
          </Grid>
        </Grid>
      </ParallaxProvider>
    </Box>
  );
}

export default LandingPage;
