import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Stack,
  Box,
  Switch,
  Button,
  Grid,
  Step,
  Stepper,
  StepButton,
  Skeleton,
} from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  CreateInput,
  CreateCheckbox,
  CreateTags,
  CreateDate,
  CreateRange,
  CreateMoney,
  CreateDuration,
  CreateTextArea,
  CreateHelp,
  isDisabeld,
} from "./FormComponent";

import {
  Question,
  QuestionKind,
  Questions,
  FormBuilderProps,
  FormState,
  Sections,
  Section,
} from "./model";

import { snackMessage, getProfileIdFromUrl } from "./utils";

import fetcher from "./fetcher";

const FormBuilder = (props: FormBuilderProps) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const [activeStep, setActiveStep] = useState(0);

  const [sectionsData, setSectionsData] = useState<Sections | undefined>(
    undefined
  );
  const [sectionsName, setSectionsName] = useState<Array<string>>([]);

  useEffect(() => {
    if (props.sections) {
      setSectionsData(props.sections);
      setSectionsName(Object.keys(props.sections));
    }
  }, [props]);

  useEffect(() => {
    let data: Record<string, any> = {};
    if (props.state === FormState.owner) {
      data = {
        function: "query_my_questions",
        body: {},
      };
    } else {
      const profileId = getProfileIdFromUrl();
      if (!profileId) {
        snackMessage({ message: "Bad Url?", severity: "warning" });
        return;
      }
      data = { function: "query_questions", body: { profile_id: profileId } };
    }
    fetcher("/api/formRespond", "POST", data)
      .then((response) => response.json())
      .then((json) => {
        setSectionsData(json.form_data);
        setSectionsName(Object.keys(json.form_data));
      })
      .catch((error) => {
        snackMessage({
          message: "Something went wrong, we are calling our best engineers",
          severity: "warning",
        });
      });
  }, []);

  const onSubmit: SubmitHandler<any> = (data) => {
    const mergedObj = _.merge({}, sectionsData, data);
    const message = {
      function: "save_questions",
      body: { form_data: data },
    };

    fetcher("/api/formRespond", "POST", message)
      .then((response) => response.json())
      .then((json) => {})
      .catch((error) => {
        snackMessage({ message: "Request sent!", severity: "success" });
      });
  };

  /* useEffect(() => {
   *   for (const section of props.sections) {
   *     for (const [name, question] of Object.entries(section.questions)) {
   *       console.log("setValue", name, question.name + ".disabled", question);
   *       setValue(question.name + ".disabled", question.disabled);
   *     }
   *   }
   * }, []);
   */
  const _createQuestion = (question: Question): JSX.Element => {
    console.log("createQuestion", question.name, question.mainName);
    switch (question.kind) {
      case QuestionKind.text:
      case QuestionKind.number:
        return CreateInput(question, register, control, watch);
      case QuestionKind.tags:
        return CreateTags(question, register, control, watch);
      case QuestionKind.help:
        return CreateHelp();
      case QuestionKind.checkbox:
        return CreateCheckbox(question, register, control, watch);
      case QuestionKind.date:
        return CreateDate(question, register, control, watch);
      case QuestionKind.range:
        return CreateRange(question, register, control, watch);
      case QuestionKind.money:
        return CreateMoney(question, register, control, watch);
      case QuestionKind.duration:
        return CreateDuration(question, register, control, watch);
      case QuestionKind.area:
        return CreateTextArea(question, register, control, watch);
      case QuestionKind.multiple:
        return CreateMultiple(question);
      default:
        console.error("FormBuilder> unknown kind", question.kind);
        return <>{question.label}</>;
    }
  };

  const createQuestion = (question: Question): JSX.Element => {
    return (
      <Box py="3" px="2" sx={{ minWidth: 100, flexGrow: 1 }}>
        {_createQuestion(question)}
      </Box>
    );
  };

  const CreateMultiple = (question: Question): JSX.Element => {
    console.log("CreateMultiple>before>", question.name);
    const components: Array<JSX.Element> = [];
    for (const [name, subQuestion] of Object.entries(question.items ?? {})) {
      subQuestion.name = question.name + "." + name;
      subQuestion.disabled = question.disabled;
      subQuestion.mainName = question.mainName;
      components.push(createQuestion(subQuestion));
    }
    console.log("CreateMultiple>after>", question.name);
    const size = Math.max(12 / components.length, 6);
    return (
      <Box>
        {question.label}
        <Grid container spacing={4}>
          {components.map((component, index) => {
            return (
              <Grid item xs={size} key={index}>
                {component}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const createQuestions = (
    questions: Questions,
    sectionName: string
  ): JSX.Element => {
    const questionComponents: Array<JSX.Element> = [];
    console.log(
      "new question --------------------------------------",
      sectionName,
      questions
    );
    let index: number = 0;
    for (const [name, question] of Object.entries(questions)) {
      index++;
      console.log("new question name", sectionName, name, question.name);
      question.mainName = sectionName + ".questions." + name; // trax> here
      question.name = sectionName + ".questions." + name;
      if (props.state === FormState.readOnly) {
        question.disabled = true;
      }
      const questionComponent = createQuestion(question);

      if (props.state === FormState.owner) {
        let switchDisabled: boolean;
        // trax> mainNmae for switch should include ".questions."
        if (getValues(`${question.mainName}.disabled`) !== undefined) {
          switchDisabled = !getValues(`${question.mainName}.disabled`);
        } else {
          switchDisabled = !question.disabled;
          console.log(
            "CreateQuestions else",
            `${question.mainName}.disabled`,
            question.disabled,
            switchDisabled,
            question.mainName
          );
        }

        questionComponents.push(
          <Grid
            container
            spacing={4}
            alignItems="center"
            key={`${question.mainName}.disabled`}
          >
            <Grid item xs>
              {questionComponent}
            </Grid>
            <Grid item>
              <Controller
                name={`${question.mainName}.disabled`}
                control={control}
                render={({ field }) => (
                  <Switch
                    defaultChecked={switchDisabled}
                    onChange={(e) => {
                      field.onChange(!e.target.checked);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        );
      } else {
        questionComponents.push(questionComponent);
      }
    }

    return (
      <Stack spacing={2}>
        {questionComponents}
        <Grid container justifyContent="flex-end"></Grid>
      </Stack>
    );
  };

  const handleNext = () => {
    if (isLastStep()) {
      return;
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      return;
    }

    setActiveStep(activeStep - 1);
  };

  const isLastStep = () => {
    return activeStep === sectionsName.length - 1;
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const createSection = (
    section: Section,
    sectionName: string
  ): JSX.Element => {
    console.log("createSection", sectionName, section);
    return (
      <Box>
        <h1>{section.label}</h1>
        {createQuestions(section.questions, sectionName)}
      </Box>
    );
  };

  const createSections = (sections: Sections | undefined): JSX.Element => {
    if (sections === undefined) {
      return (
        <>
          <Skeleton variant="circular" />
          <Skeleton variant="circular" />
          <Skeleton variant="circular" />
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </>
      );
    }
    console.log("sections", sections);
    const components: Array<JSX.Element> = [];
    let index = 0;
    for (const [name, section] of Object.entries(sections)) {
      section.name = name;
      components.push(
        <Step key={name} completed={true}>
          <StepButton onClick={handleStep(index)}>{section.label}</StepButton>
        </Step>
      );
      index++;
    }

    console.log(
      "aha",
      "active step",
      activeStep,
      "sectionsName",
      sectionsName,
      sections[sectionsName[activeStep]],
      sectionsName[activeStep]
    );

    return (
      <Box pb="8">
        <Stepper activeStep={activeStep}>{components}</Stepper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ minHeight: "70vh" }}>
            {createSection(
              sections[sectionsName[activeStep]],
              sectionsName[activeStep]
            )}
          </Box>
          <Box display="flex">
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Box sx={{ flexGrow: 1 }}> </Box>

            <Button
              name="next"
              onClick={handleNext}
              style={{ visibility: isLastStep() ? "hidden" : "visible" }}
            >
              Next
            </Button>

            <Button
              name="sub"
              type="submit"
              variant="contained"
              style={{ visibility: !isLastStep() ? "hidden" : "visible" }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    );
  };
  return createSections(sectionsData);
};

export default FormBuilder;
