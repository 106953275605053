import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Grid,
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Switch,
  Menu,
  MenuItem,
  useMediaQuery,
  Divider,
  Modal,
} from "@mui/material";
import { Menu as MenuIcon, DarkMode, LinkedIn } from "@mui/icons-material";
import { useTheme, Theme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from "aws-amplify";
import { lightBlue } from "@mui/material/colors";
import { AmplifyUser } from "@aws-amplify/ui";
import cognitoConf from "./cognito.json";
import { CheckboxField as Checkbox } from "@aws-amplify/ui-react";
import { isValidEmail, checkPassword } from "./utils";
import { useRegister } from "./Context";

const logoDark = require("./logo-dark.png");
const logoLight = require("./logo-light.png");
type ValidationError = Record<string, string | string[]>;

const Logo = (theme: Theme) => {
  return (
    <Link to="/">
      <img
        src={theme.palette.mode === "dark" ? logoDark : logoLight}
        style={{ height: "50px" }}
        alt="logo"
      />
    </Link>
  );
};

const MenuBar = (props: Record<string, any>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [burgerAnchor, setBurgerAnchor] = useState<null | HTMLElement>(null);

  const [isDark, setIsDark] = useState(
    localStorage.getItem("isDark") === "true"
  );
  const navigate = useNavigate();

  const isConnected = (user: AmplifyUser | undefined) => {
    console.log("user", user);
    setConnectedUser(user);
    if (user) {
      setShowAuthModal(false);
    }
    // const profileId = user.attributes
    //   ? user.attributes["custom:profileId"]
    //   : null;
    //navigate("/" + profileId);
  };

  const [connectedUser, setConnectedUser] = useState<AmplifyUser | undefined>(
    undefined
  );
  const [avatarAnchor, setAvatarAnchor] = useState<null | HTMLElement>(null);

  const [showAuthModal, setShowAuthModal] = useRegister("showModal", false);

  useEffect(() => {
    console.log("useEffect???");
    // Save the current theme in local storage whenever it changes
    (cognitoConf as any)["Auth"] = {
      oauth: {
        domain: cognitoConf.auth_domain,
        scope: ["openid", "profile", "email"],
        redirectSignIn: `https://${window.location.hostname}`,
        redirectSignOut: `https://${window.location.hostname}`,
        responseType: "code",
        clientId: cognitoConf.aws_user_pools_web_client_id,
      },
      cookieStorage: {
        path: "/",
        expires: 365,
        sameSite: "strict",
        secure: true,
        domain: window.location.hostname,
      },
    };

    console.log("auth conf", cognitoConf);
    Auth.configure(cognitoConf);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("auth>currentAuthenticated>", user);
        setConnectedUser(user);
        if (user) {
          setShowAuthModal(false);
        }
      })
      .catch((error) => {
        isConnected(undefined);
        console.log("auth>currentAuthenticated>error", error);
      });
  }, []);

  useEffect(() => {
    console.log("useEffect??? dark");
    localStorage.setItem("isDark", String(isDark));
    props.setTheme(isDark ? "dark" : "light");
  }, [isDark, props]);

  const darkSwitch = () => {
    return (
      <Box display="flex" alignItems="center" px={3}>
        <Switch
          checked={isDark} // set the switch position based on state
          onChange={(e) => {
            setIsDark((e.target as HTMLInputElement).checked); // update local state and trigger useEffect
          }}
        />
        <DarkMode />
      </Box>
    );
  };

  const loginAvatar = () => {
    return (
      <>
        {!connectedUser ? (
          <Button
            color="inherit"
            onClick={() => {
              setShowAuthModal(true);
            }}
          >
            Login
          </Button>
        ) : (
          <Box>
            <Avatar
              sx={{ bgcolor: lightBlue[800] }}
              onClick={(event) => {
                {
                  setAvatarAnchor(event.currentTarget);
                }
              }}
            >
              {connectedUser
                ? ((connectedUser as AmplifyUser).username || "a")[0]
                : ""}
            </Avatar>
            <Menu
              anchorEl={avatarAnchor}
              open={Boolean(avatarAnchor)}
              onClose={() => {
                setAvatarAnchor(null);
              }}
            >
              <MenuItem>Settings</MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  setConnectedUser(undefined);
                  Auth.signOut();
                  navigate("/");
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        )}
      </>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }} mb={8}>
      <AppBar position="static">
        <Toolbar>
          {isMobile ? (
            <Grid container direction="row" alignItems="center">
              <Grid item xs={4}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={(event) => {
                    setBurgerAnchor(event.currentTarget);
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={burgerAnchor}
                  open={Boolean(burgerAnchor)}
                  onClose={() => {
                    setBurgerAnchor(null);
                  }}
                >
                  <MenuItem>
                    <Link
                      to="/profile"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Profile
                    </Link>
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item xs={4}>
                {Logo(theme)}
              </Grid>
              <Grid item xs={3}>
                {darkSwitch()}
              </Grid>
              <Grid item xs={1}>
                {loginAvatar()}
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  {Logo(theme)}
                </IconButton>
              </Grid>
              <Grid item>
                {connectedUser && (
                  <Button color="inherit">
                    <Link
                      to="/profile"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Profile
                    </Link>
                  </Button>
                )}
              </Grid>
              <Grid item style={{ flexGrow: 1 }}></Grid>
              <Grid item justifyContent="flex-end">
                {darkSwitch()}
              </Grid>{" "}
              <Grid item justifyContent="flex-end">
                {loginAvatar()}
              </Grid>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
      <Modal
        open={showAuthModal}
        onClose={() => {
          setShowAuthModal(false);
        }}
      >
        <Authenticator
          loginMechanisms={["username", "email"]}
          components={{
            SignIn: {
              Header() {
                return (
                  <>
                    <Container>
                      <Button
                        onClick={() => {
                          Auth.federatedSignIn({ provider: "linkedin" as any });
                        }}
                      >
                        <LinkedIn />
                      </Button>
                    </Container>
                  </>
                );
              },
            },
            SignUp: {
              FormFields() {
                return (
                  <>
                    <Button
                      onClick={() => {
                        Auth.federatedSignIn({ provider: "linkedin" as any });
                      }}
                    >
                      <LinkedIn />
                    </Button>
                    <Authenticator.SignUp.FormFields />

                    <Checkbox
                      name="terms"
                      id="terms"
                      errorMessage="You must agree before submitting."
                      label={
                        <Link to="/terms-of-service">
                          I agree with the terms and conditions{" "}
                        </Link>
                      }
                    />
                  </>
                );
              },
            },
          }}
          services={{
            async validateCustomSignUp(formData) {
              const validationErrors: ValidationError = {};
              if (formData.email && !isValidEmail(formData.email)) {
                validationErrors.email = "Mail is not valid";
              }

              if (formData.username && formData.username.includes("@")) {
                validationErrors.username = "Username can't contain @";
              }

              const unmetConditions = checkPassword(formData.password);
              if (unmetConditions.length > 0) {
                validationErrors.password = unmetConditions.join(", ");
              }

              if (formData.terms !== "on") {
                validationErrors.terms =
                  "You must agree to the Terms & Conditions";
              }

              return validationErrors;
            },
          }}
        >
          {({ signOut, user }) => {
            isConnected(user);
            return <></>;
          }}
        </Authenticator>
      </Modal>
    </Box>
  );
};

export default MenuBar;
