import React, { useState, useReducer } from "react";
import { Box, Container } from "@mui/material";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { lighten, darken } from "@mui/system";
import FormBuilder from "./FormBuilder";
import Menu from "./Menu";
import Footer from "./Footer";
import { FormState, Sections } from "./model";
import LandingPage from "./Landing";

import { SECTIONS_TEST, RESULTS } from "./fake";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import DocMarkdown from "./DocMarkdown";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useRegister } from "./Context";

declare module "@mui/material/styles" {
  interface Palette {
    backgrounds: Palette["primary"];
  }

  interface PaletteOptions {
    backgrounds?: PaletteOptions["primary"];
  }
}

const _darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const darkTheme = createTheme(_darkTheme, {
  palette: {
    action: {
      hover: "#222222",
    },
    backgrounds: {
      main: _darkTheme.palette.background.default,
      light: "#222222",
      dark: "#070707",
      contrastText: "#242105",
    },
  },
});
const undraw = require("./undraw.png");

const _lightTheme = createTheme({ palette: { mode: "light" } });

const lightTheme = createTheme(_lightTheme, {
  palette: {
    mode: "light",
    action: {
      hover: "#e0e0ef",
    },
    primary: {
      main: "#0d47a1",
    },
    backgrounds: {
      main: "#f0f0ff",
      light: "#f9f9ff",
      dark: "#f0f0ff",
      contrastText: "#242105",
    },
  },
});

const Login = () => {
  return <></>;
};

const Responses = () => {
  return <></>;
};

const Account = () => {
  return <></>;
};
const Profile = () => {
  return (
    <>
      <Container style={{ minHeight: "80vh" }}>
        <FormBuilder state={FormState.owner} />
      </Container>
    </>
  );
};

const App = () => {
  const [theme, setTheme] = useState(
    localStorage.getItem("isDark") === "true" ? darkTheme : lightTheme
  );
  const [snackBarState, setSnackBarState] = useRegister("snackBar", {
    open: false,
    message: "",
    kind: "success",
  });
  const closeSnack = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Box style={{ minHeight: "100vh" }}>
          <CssBaseline />
          <Menu
            setTheme={(themeName: string) => {
              setTheme(themeName === "dark" ? darkTheme : lightTheme);
            }}
          />
          <Routes>
            <Route index path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/responses" element={<Responses />} />
            <Route
              path="/privacy-policy"
              element={<DocMarkdown fileName="privacy" />}
            />
            <Route
              path="/terms-of-service"
              element={<DocMarkdown fileName="termsofservices" />}
            />
            <Route
              path="/mention-legale"
              element={<DocMarkdown fileName="mentionlegale" />}
            />
            <Route path="/account" element={<Account />} />
            <Route path="*" element={<Profile />} />
          </Routes>
        </Box>

        <Snackbar
          open={snackBarState.open}
          autoHideDuration={6000}
          onClose={closeSnack}
        >
          <Alert
            onClose={closeSnack}
            severity={snackBarState.severity}
            sx={{ width: "100%" }}
          >
            {snackBarState.message}
          </Alert>
        </Snackbar>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
