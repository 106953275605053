export enum QuestionKind {
  tags = "tags",
  checkbox = "checkbox",
  radios = "radios",
  text = "text",
  number = "number",
  date = "date",
  range = "range",
  multiple = "multiple",
  money = "money",
  duration = "duration",
  area = "area",
  help = "help",
}
export enum QuestionOperator {
  gte = "$gte",
  gt = "$gt",
  eq = "$eq",
  ne = "$ne",
  lt = "$lt",
  lte = "$lte",
  oneOf = "oneOf",
  noneOf = "noneOf",
}

export interface Question {
  name: string;
  label: string;
  kind: QuestionKind;
  props?: Record<string, any>;
  mainName?: string;
  operator?: QuestionOperator;
  disabled?: boolean;
  helperText?: string;
  item?: string | number | boolean | number[];
  items?: Record<string, Question>;

  value?: string | number | boolean | number[] |string[];
}

export enum FormState {
  readOnly = "readOnly",
  owner = "owner",
  other = "oher",
}

export type Questions = Record<string, Question>;

export interface Section {
  label: string;
  questions: Questions;
  name?: string;
}
export type Sections = Record<string, Section>;

export interface FormBuilderProps {
  state: FormState;
  sections?: Sections;
}

